import { computed, inject, Injectable, Signal } from '@angular/core';
import { ProjectStore } from '@app/shared/services/project-store/project.store';

@Injectable({
  providedIn: 'root'
})
export class RouteTitleService {
  private readonly _projectStore = inject(ProjectStore);
  
  private currentProjectSignal = this._projectStore.projectComputed;

  public pageTitle: Signal<string> = computed(() => {
    const project = this.currentProjectSignal();

    let title = '';
    
    if (project) {
      title = `${project.name}`;
    }
    
    return title;
  });
}