<nav mat-tab-nav-bar [tabPanel]="tabPanel">
  @for (menuItem of menu$ | async; track menuItem; let index = $index) {
    <ng-template
      [ngxPermissionsOnly]="menuItem.permissions?.only!"
      [ngxPermissionsExcept]="menuItem.permissions?.except!">
      @if (menuItem.type === 'link') {
        <a mat-button
          [routerLink]="buildRoute([menuItem.route])" routerLinkActive="active">
          <ng-container
            [ngTemplateOutlet]="linkTypeTpl"
            [ngTemplateOutletContext]="{item: menuItem}" />
        </a>
      }
      @if (menuItem.type === 'extLink') {
        <a mat-button [href]="menuItem.route">
          <ng-container
            [ngTemplateOutlet]="linkTypeTpl"
            [ngTemplateOutletContext]="{item: menuItem}" />
        </a>
      }
      @if (menuItem.type === 'extTabLink') {
        <a mat-button [href]="menuItem.route" target="_blank">
          <ng-container
            [ngTemplateOutlet]="linkTypeTpl"
            [ngTemplateOutletContext]="{item: menuItem}" />
        </a>
      }
      @if (menuItem.type === 'sub') {
        <button mat-button [matMenuTriggerFor]="submenu.menuPanel"
          routerLinkActive #rla="routerLinkActive"
          [class.active]="rla.isActive || menuStates[index].active">
          <ng-container
            [ngTemplateOutlet]="linkTypeTpl"
            [ngTemplateOutletContext]="{item: menuItem}" />

          <app-topmenu-panel #submenu
            [items]="menuItem.children!"
            [parentRoute]="[menuItem.route]"
            [level]="1"
            (routeChange)="onRouteChange($event, index)" />

        </button>
      }
    </ng-template>
  }
</nav>

<mat-tab-nav-panel #tabPanel />

<ng-template #linkTypeTpl let-item="item" let-level="level">
  <mat-icon class="menu-icon">{{item.icon}}</mat-icon>
  <span class="menu-name">{{item.name | translate}}</span>
  @if (item.label) {
    <span class="menu-label bg-{{item.label.color}}">{{item.label.value}}</span>
  }
  @if (item.badge) {
    <span class="menu-badge bg-{{item.badge.color}}">{{item.badge.value}}</span>
  }
  @if (item.type!=='link') {
    <mat-icon class="menu-caret">{{item.type==='sub' ? 'arrow_drop_down' : 'launch'}}</mat-icon>
  }
</ng-template>
