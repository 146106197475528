@use '@angular/material' as mat;
@use '../style/badge';

.matero-sidemenu {
  padding: 0;
  margin: 0;
  list-style: none;

  .menu-item {
    overflow: hidden;
    border-radius: 1.5rem;
    will-change: transform; // fix expansion in Safari

    >.menu-heading {
      &:hover,
      &:focus {
        background-color: var(--sidemenu-heading-hover-background-color);
      }
    }

    &.active {
      >.menu-heading {
        color: var(--sidemenu-active-heading-text-color);
        background-color: var(--sidemenu-active-heading-background-color);

        &:hover,
        &:focus {
          background-color: var(--sidemenu-active-heading-hover-background-color);
        }
      }
    }

    &.expanded {
      background-color: var(--sidemenu-expanded-background-color);

      >.menu-toggle>.menu-caret {
        transform: rotate(-180deg);
      }
    }

    &:has(.active)>.menu-heading {
      color: var(--sidemenu-active-heading-text-color);
    }
  }

  .menu-heading {
    position: relative;
    display: flex;
    align-items: center;
    width: calc(var(--sidenav-width) - 1rem);
    padding: 0.75rem;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-radius: 1.5rem;
    outline: none;
  }

  .menu-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.75rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-align: center;

    [dir='rtl'] & {
      margin-right: 0;
      margin-left: 0.75rem;
    }

    &.submenu-icon {
      position: absolute;
      left: 0.75rem;
      width: 1.5rem;
      height: 1.5rem;
      font-size: 1rem;
      line-height: 1.5rem;
      opacity: 0;

      [dir='rtl'] & {
        right: 0.75rem;
        left: 0;
      }
    }
  }

  .menu-caret {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    transition: transform mat.$private-swift-ease-out-duration mat.$private-swift-ease-out-timing-function;
  }

  .menu-name {
    flex: 1;
    overflow: hidden;
    text-align: initial;
    text-overflow: ellipsis;
  }

  .menu-icon,
  .menu-name,
  .menu-label,
  .menu-badge {
    transition: opacity mat.$private-swift-ease-out-duration mat.$private-swift-ease-out-timing-function;
  }

  .menu-label,
  .menu-badge {
    @include badge.badge();
  }

  .menu-badge {
    border-radius: 50rem;
  }

  &.level-1>li>.menu-heading {
    padding-left: 3rem;

    [dir='rtl'] & {
      padding-right: 3rem;
      padding-left: 1rem;
    }
  }

  &.level-2>li>.menu-heading {
    padding-left: 4rem;

    [dir='rtl'] & {
      padding-right: 4rem;
      padding-left: 1rem;
    }
  }

  &.level-2 [class^='level-']>li>.menu-heading {
    padding-left: 5rem;

    [dir='rtl'] & {
      padding-right: 5rem;
      padding-left: 1rem;
    }
  }
}
