@use '@angular/material' as mat;

.matero-user-panel {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  background-color: var(--user-panel-background-color);
  border-radius: 0.75rem;
  outline: none;

  &:hover,
  &:focus {
    background-color: var(--user-panel-hover-background-color);
  }
}

// Set default width and height can avoid flashing before avatar image loaded.
.matero-user-panel-avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 50rem;
  transition: transform mat.$private-swift-ease-out-duration mat.$private-swift-ease-out-timing-function;
  transform-origin: 0 1.5rem;

  [dir='rtl'] & {
    transform-origin: 3rem 1.5rem;
  }
}

.matero-user-panel-info {
  flex: 1;
  width: 0;
  margin-left: 0.75rem;
  opacity: 1;
  transition: opacity mat.$private-swift-ease-out-duration mat.$private-swift-ease-out-timing-function;

  [dir='rtl'] & {
    margin-right: 0.75rem;
    margin-left: 0;
  }

  h4,
  h5 {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }

  h4 {
    margin-bottom: 4px;
    font-size: 1rem;
    font-weight: 500;
  }

  h5 {
    font-size: 0.75rem;
    font-weight: normal;
  }
}
