import { computed, effect, inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Menu, MenuChildrenItem, MenuService } from '@app/core';
import { ProjectStore } from '@app/shared/services/project-store/project.store';
import { Study, Workload } from '@cloud-api';
import { NgxPermissionsService } from 'ngx-permissions';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppMenuService {
  private readonly _permissionService = inject(NgxPermissionsService);
  private readonly _menuService = inject(MenuService);
  private readonly _projectStore = inject(ProjectStore);

  workloads = this._projectStore.workloadsComputed;
  studies = this._projectStore.studiesComputed;
  projectId = this._projectStore.projectIdComputed;

  private permissionsSignal = toSignal(
    this._permissionService.permissions$.pipe(
      map((permissions) => Object.keys(permissions)),
    ),
  );

  public menu = computed(() => this.buildMenu());

  constructor() {
    // Effect to explicitly watch for projectId, workloads, and studies changes to trigger rebuild
    effect(() => {
      const currentProjectId = this.projectId();
      const currentWorkloads = this.workloads();
      const currentStudies = this.studies();
      
      // Trigger menu rebuild by accessing the computed menu signal
      this._menuService.set(this.menu());
    });
  }

  private buildMenu(): Menu[] {
    const workloads = this.workloads();
    const studies = this.studies();
    const projectId = this.projectId();
    const permissions = this.permissionsSignal();

    let menu: Menu[] = [];

    if (
      projectId && workloads && studies &&
      (permissions.includes('project_admin') ||
        permissions.includes('project_manager') ||
        permissions.includes('project_user') ||
        permissions.includes('project_read_only'))
    ) {
      menu = this.buildProjectMenu(projectId, workloads, studies, permissions);
    }

    if (permissions.includes('admin')) {
      menu.push(this.buildAdminMenu());
    }

    return menu;
  }

  private buildProjectMenu(projectId: string, workloads: Workload[], studies: Study[], roles: string[]): Menu[] {
    const workloadsMenu = this.buildWorkloadsMenu(workloads);
    const studiesMenu = this.buildStudiesMenu(studies, roles);

    console.log('build menu');

    let menu: Menu[] = [
      {
        route: `p/${projectId}/dashboard`,
        name: 'menu.dashboard',
        type: 'link',
        icon: 'dashboard',
      },
    ];

    if (roles.includes('project_admin') || roles.includes('project_manager')) {
      menu.push({
        route: `p/${projectId}/workloads`,
        name: 'menu.workloads',
        type: 'sub',
        icon: 'build',
        children: workloadsMenu,
      });
    }

    menu.push({
      route: `p/${projectId}/studies`,
      name: 'menu.studies',
      type: 'sub',
      icon: 'folder_open',
      children: studiesMenu,
    });

    if (roles.includes('project_admin')) {
      menu.push({
        route: `p/${projectId}/settings`,
        name: 'menu.project.settings',
        type: 'sub',
        icon: 'tune',
        children: [
          {
            name: 'menu.users',
            type: 'link',
            route: '/users',
          },
          {
            name: 'menu.options',
            type: 'link',
            route: '/options',
          },
        ],
      });
    }

    return menu;
  }

  private buildWorkloadsMenu(workloads: Workload[]): MenuChildrenItem[] {
    const workloadsMenu =
      workloads?.map(
        (workload) =>
          ({
            name: workload.name,
            type: 'link',
            route: `/${workload.id}`,
          }) as MenuChildrenItem,
      ) || [];

    workloadsMenu.push({
      name: 'menu.add-workload',
      type: 'link',
      route: '/add',
    });

    return workloadsMenu;
  }

  private buildStudiesMenu(
    studies: Study[],
    roles: string[],
  ): MenuChildrenItem[] {
    const studiesMenu =
      studies?.map(
        (study) =>
          ({
            name: study.name,
            type: 'link',
            route: `/${study.id}`,
          }) as MenuChildrenItem,
      ) || [];

    if (
      roles.includes('project_admin') ||
      roles.includes('project_manager') ||
      roles.includes('project_user')
    ) {
      studiesMenu.push({
        name: 'menu.add-study',
        type: 'link',
        route: '/add',
      });
    }

    return studiesMenu;
  }

  private buildAdminMenu(): Menu {
    return {
      name: 'menu.admin',
      type: 'sub',
      route: 'admin',
      icon: 'settings',
      children: [
        {
          route: 'users',
          name: 'menu.users',
          type: 'link',
        },
        {
          route: 'projects',
          name: 'menu.projects',
          type: 'link',
        },
        {
          name: 'menu.container-monitor',
          type: 'link',
          route: '/cmonitor',
        },
        {
          name: 'menu.container-logs',
          type: 'link',
          route: '/clogs',
        },
      ],
    };
  }
}
