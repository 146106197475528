@if (auth.isAuthenticated$ | async) {
<mat-card class="welcome-card">
  <mat-card-content>
    Select Project:
    @if (projects$ | async; as projects) {
    <mat-nav-list>
      @for (project of projects; track project.id) {
      <a mat-list-item [routerLink]="['p', project.id, 'dashboard']"> {{ project.name }} </a>
      }
    </mat-nav-list>
    }
  </mat-card-content>
</mat-card>
} @else {
<mat-card class="welcome-card">
  <mat-card-header>
    <mat-card-title>{{'frontpage.welcome-to' | translate}} CloudSim</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>{{'frontpage.opening-paragraph' | translate}}</p>
    <h3>{{'frontpage.what-we-offer' | translate}}</h3>
    <mat-list>
      <mat-list-item>
        <mat-icon matListItemIcon>build</mat-icon>
        <div matListItemTitle>{{'frontpage.point1.title' | translate}}</div>
        <div matListItemLine>{{'frontpage.point1.description' | translate}}</div>
      </mat-list-item>
      <mat-list-item>
        <mat-icon matListItemIcon>cloud</mat-icon>
        <div matListItemTitle>{{'frontpage.point2.title' | translate}}</div>
        <div matListItemLine>{{'frontpage.point2.description' | translate}}</div>
      </mat-list-item>
      <mat-list-item>
        <mat-icon matListItemIcon>shuffle</mat-icon>
        <div matListItemTitle>{{'frontpage.point3.title' | translate}}</div>
        <div matListItemLine>{{'frontpage.point3.description' | translate}}</div>
      </mat-list-item>
      <mat-list-item>
        <mat-icon matListItemIcon>dashboard</mat-icon>
        <div matListItemTitle>{{'frontpage.point4.title' | translate}}</div>
        <div matListItemLine>{{'frontpage.point4.description' | translate}}</div>
      </mat-list-item>
      <mat-list-item>
        <mat-icon matListItemIcon>transform</mat-icon>
        <div matListItemTitle>{{'frontpage.point5.title' | translate}}</div>
        <div matListItemLine>{{'frontpage.point5.description' | translate}}</div>
      </mat-list-item>
    </mat-list>
    <p>{{'frontpage.closing-paragraph' | translate}}</p>

    <button mat-stroked-button (click)="auth.loginWithRedirect()">Login</button>

  </mat-card-content>
</mat-card>
}