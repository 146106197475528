import { VERSION } from './version';

export const environment = {
  apiUrl: 'https://cloud-web-application-api.azurewebsites.net',
  production: true,
  baseUrl: '',
  useHash: false,
  i18nPrefix: '',
  versions: {
    app: VERSION.semverString,
    tag: VERSION.tag,
  },
  auth: {
    clientId: 'fKegsoUB9SclTxJs3hWAaGKUk98gKptC',
    domain: 'id-cloud-run.au.auth0.com', // e.g., you.auth0.com
    authorizationParams: {
      redirect_uri: `${window.location.origin}`,
      scope: 'openid profile email offline_access',
      audience: 'https://cloud-web-application-api.azurewebsites.net',
    },
    useRefreshTokens: true
  },
  httpInterceptor: {
    allowedList: ['https://cloud-web-application-api.azurewebsites.net/*']
  },
};
