import { Injectable, inject } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UpdateNotifierService {
  private swUpdate = inject(SwUpdate);

  init() {
    // Delay the initialization to ensure Service Worker is ready
    setTimeout(() => this.initializeSwUpdate(), 5000);
  }

  private initializeSwUpdate() {
    if (!this.swUpdate.isEnabled) {
      console.warn('Service Worker updates are not enabled');
      return;
    }

    console.log('Service Worker updates are enabled');

    // Check for updates on init
    this.checkForUpdate();

    // Subscribe to version updates
    this.swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(() => {
        if (confirm('A new version of the application is available. Load it?')) {
          window.location.reload();
        }
      });

    // Check for updates every 6 hours
    setInterval(() => {
      this.checkForUpdate();
    }, 6 * 60 * 60 * 1000);
  }

  private checkForUpdate() {
    this.swUpdate.checkForUpdate()
      .then(() => console.log('Checked for updates'))
      .catch(err => console.error('Error when checking for updates:', err));
  }
}