import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { environment as env } from '../../../environments/environment';

@Component({
  selector: 'app-about-dialog',
  template: ` <mat-dialog-content>
      <h2>About CloudSim</h2>
      <p><strong>Version:</strong> {{ version }}</p>
      <p>
        CloudSim helps streamline complex simulations, Monte Carlo analyses, and
        computational experiments. It allows you to create, manage, and rerun
        workload packages efficiently.
      </p>
      <ul>
        <li>Flexible workload management</li>
        <li>Cloud-based execution</li>
        <li>Random seed variability for simulations</li>
      </ul>
      <p><small>Developed by InterDynamics Pty. Ltd.</small></p>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Close</button>
    </mat-dialog-actions>`,
  standalone: true,
  imports: [MatButtonModule, MatDialogModule],
})
export class AboutDialogComponent {
  version = env.versions.app;
}
