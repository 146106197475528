.matero-header {
  --mat-toolbar-container-background-color: transparent;

  position: relative;
  z-index: 200;
  display: block;
  background-color: var(--header-background-color);
  backdrop-filter: blur(8px);
}

.page-title {
  padding-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}