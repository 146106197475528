import { Injectable } from '@angular/core';
import { Data, Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { RouterStateUrl } from './router.state';

@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  // serialize(routerState: RouterStateSnapshot): RouterStateUrl {
  //   let route = routerState.root;

  //   while (route.firstChild) {
  //     route = route.firstChild;
  //   }

  //   const {
  //     url,
  //     root: { queryParams }
  //   } = routerState;
  //   const { params } = route;

  //   return { url, params, queryParams };
  // }
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    return {
      url: routerState.url,
      params: mergeRouteParams(routerState.root, (r) => r.params),
      queryParams: mergeRouteParams(routerState.root, (r) => r.queryParams)
      // data: mergeRouteData(routerState.root)
    };
  }
}

function mergeRouteParams(route: any, getter: (r: any) => Params): Params {
  if (!route) {
    return {};
  }
  const currentParams = getter(route);
  const primaryChild =
    route.children.find((c: any) => c.outlet === 'primary') || route.firstChild;
  return { ...currentParams, ...mergeRouteParams(primaryChild, getter) };
}

function mergeRouteData(route: any): Data {
  if (!route) {
    return {};
  }

  const currentData = route.data;
  const primaryChild =
    route.children.find((c: any) => c.outlet === 'primary') || route.firstChild;
  return { ...currentData, ...mergeRouteData(primaryChild) };
}
