import { FileDetails, Project, ProjectOptions, Workload } from '@cloud-api';
import { createAction, props } from '@ngrx/store';

export const loadProject = createAction(
  '[Project] Load Project', props<{ project_id: string }>()
);
export const loadProjectSuccess = createAction(
  '[Project] Load Project Success',
  props<{ project?: Project }>()
);
export const loadProjectFailure = createAction(
  '[Project] Load Workload Failure',
  props<{ error: any }>()
);

export const loadWorkload = createAction(
  '[Project] Load Workload', props<{ project_id: string, workload_id: string }>()
);
export const loadWorkloadSuccess = createAction(
  '[Project] Load Workload Success',
  props<{ workload?: Workload }>()
);
export const loadWorkloadFailure = createAction(
  '[Project] Load Workload Failure',
  props<{ error: any }>()
);

export const loadWorkloadFiles = createAction(
  '[Project] Load Workload Files', props<{ project_id: string, workload_id: string }>()
);
export const loadWorkloadFilesSuccess = createAction(
  '[Project] Load Workload Files Success',
  props<{ files?: FileDetails[] }>()
);
export const loadWorkloadFilesFailure = createAction(
  '[Project] Load Workload Files Failure',
  props<{ error: any }>()
);

export const saveProjectOptions = createAction(
  '[Project] SaveProjectOptions',
  props<{ projectOptions: ProjectOptions }>()
);

export const reloadProject = createAction('[Project] Reload Project');
export const reloadWorkloadFiles = createAction('[Project] Reload Workload Files');
export const resetProject = createAction('[Active Project] Reset');
export const clearProjectData = createAction('[Active Project] Clear Data');