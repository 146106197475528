import { AsyncPipe } from '@angular/common';
import { Component, ViewEncapsulation, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-user-panel',
  template: `
    @if (auth.user$ | async; as user) {
      <div class="matero-user-panel" routerLink="/profile/overview">
        <img
          class="matero-user-panel-avatar"
          [src]="user.picture"
          alt="avatar"
          width="64"
        />
        <div class="matero-user-panel-info">
          <h4>{{ user.name }}</h4>
          <h5>{{ user.email }}</h5>
        </div>
      </div>
    }
  `,
  styleUrl: './user-panel.component.scss',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    AsyncPipe,
    RouterLink,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
  ],
})
export class UserPanelComponent {
  public readonly auth = inject(AuthService);
}
