import { createReducer, on } from '@ngrx/store';
import * as ProjectActions from './project-store.actions';
import { resetProject } from './project-store.actions';
import { ProjectStoreState } from './project-store.state';

export const projectFeatureKey = 'project';

export const initialState: ProjectStoreState = {
  project: undefined,
  workload: undefined,
  files: undefined,
};

export const reducer = createReducer(
  initialState,
  on(ProjectActions.loadProject, state => state),
  on(ProjectActions.loadProjectSuccess, (state, action) => { return { ...state, project: action.project } }),
  on(ProjectActions.loadProjectFailure, (state, action) => state),
  on(ProjectActions.loadWorkload, state => state),
  on(ProjectActions.loadWorkloadSuccess, (state, action) => { return { ...state, workload: action.workload, files: undefined } }),
  on(ProjectActions.loadWorkloadFailure, (state, action) => state),
  on(ProjectActions.loadWorkloadFiles, state => state),
  on(ProjectActions.loadWorkloadFilesSuccess, (state, action) => { return { ...state, files: action.files } }),
  on(ProjectActions.loadWorkloadFilesFailure, (state, action) => state),
  
  on(resetProject, (state) => {
    return { ...state, project: undefined };
  }),
);

