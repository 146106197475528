import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromProject from './project-store.reducer';
import { ProjectStoreState } from './project-store.state';

export const selectProjectState = createFeatureSelector<ProjectStoreState>(
  fromProject.projectFeatureKey
);

export const getProject = createSelector(
  selectProjectState,
  (projectstate: ProjectStoreState) => projectstate?.project
);

export const selectWorkload = createSelector(
  selectProjectState,
  (state) => {
    return state.workload;
  }
);

export const getProjectOptions = createSelector(
  selectProjectState,
  (projectstate: ProjectStoreState) => projectstate?.project?.projectOptions
);

export const selectWorkloadFiles = createSelector(
  selectProjectState,
  (state) => {
    return state.files;
  }
);

export const getProjectId = createSelector(
  selectProjectState,
  (projectstate: ProjectStoreState) => projectstate?.project?.id
);

export const getUserRole = (id: string | undefined) => createSelector(
  selectProjectState,
  (projectstate: ProjectStoreState) => projectstate?.project?.projectAppUsers?.find(u => u.id == id)?.role
);

export const getStudies = createSelector(
  selectProjectState,
  (projectstate: ProjectStoreState) => projectstate?.project?.studies
);

export const getProjectWorkloads = createSelector(
  selectProjectState,
  (projectstate: ProjectStoreState) => projectstate?.project?.workloads
);
