<mat-toolbar>
  @if (showToggle) {
    <button mat-icon-button (click)="toggleSidenav.emit()">
      <mat-icon>menu</mat-icon>
    </button>
  }

  @if (showBranding) {
    <app-branding />
  }

  @if (showTitle) {
    <h1 class="page-title">{{ titleService.pageTitle() }}</h1>
  }

  <span class="flex-fill"></span>

  <!-- <button mat-icon-button>
    <mat-icon>search</mat-icon>
  </button> -->

  <app-translate />

  <button mat-icon-button class="hide-small" (click)="toggleFullscreen()">
    <mat-icon>fullscreen</mat-icon>
  </button>

  <!-- <app-notification class="hide-small" /> -->

  <app-user />

  <!-- <button mat-icon-button class="hide-small" (click)="toggleSidenavNotice.emit()">
    <mat-icon>list</mat-icon>
  </button> -->
</mat-toolbar>
